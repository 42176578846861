const Index = {
    'Nav30DataSource.Home':'Home',
    'Nav30DataSource.Philosophy':'Philosophy',
    'Nav30DataSource.Team':'Team',
    'Nav30DataSource.KnowlegeBank':'KnowlegeBank',
    'Nav30DataSource.Careers':'Careers',
    'Banner50DataSource.title1':'An Innovative ',
    'Banner50DataSource.title2':'Investment',
    'Banner50DataSource.title3':' Paradigm',
    'Banner50DataSource.content': `Shixiang is redefining investment services. We provide best LP
    services for entrepreneurs and investors who want to participate
    in technical revolutions in the next generation`,
    'Feature70DataSource.title':' Your partner for global alternative investments',
    'Feature70DataSource.content':' As a global investment platform, we have access to wide range of unique assets, identifying high-quality investment targets, and prioritizing the allocation of scarce resources.',
    'Feature70DataSource.block1':'An investment DAO',
    'Feature70DataSource.block2':'A global investment platform',
    'Feature70DataSource.block3':'Founders backing founders',
    'Feature70DataSource.block4':'Ecosystem of connections and exchange of insights',
    'Content00DataSource.title':'Market Positioning',
    'Content00DataSource.content':'We are committed to democratizing knowledge and insights, empowering our clients to make independent investment decisions.',
    'Content00DataSource.block1.title':'Flexibility& information Democracy',
    'Content00DataSource.block2.title':'Decentralization',
    'Content00DataSource.block3.title':'Insights& good Judgement',
    'Content00DataSource.block1.content':'When choosing an exchange, trust matters. That’s why security has been and will always be our top consideration. ',
    'Content00DataSource.block2.content':'We believe in the potential of blockchain to provide groundbreaking solutions across industries and beyond crypto. ',
    'Content00DataSource.block3.content':'When choosing an exchange, trust matters. That’s why security has been and will always be our top consideration.',
    'Feature00DataSource.title':'Data highlights',
    'Feature00DataSource.block1.title':'over $1 B AUM',
    'Feature00DataSource.block2.title':'global unicorn portfolios',
    'Feature00DataSource.block3.title':'MOIC15 professionals',
    'Feature00DataSource.block4.title':'HFs in Database',
    'Feature00DataSource.block5.title':'Companies Screened each year',
    'Feature00DataSource.block6.title':'Founders Networks to Exchange Insights',
    'Teams00DataSource.title':'About',
    'Teams00DataSource.content':'Shixiang was established at the end of 2019 by former Sequoia Capital investor Li Guangmi. Core team is from Sequoia China with a new generation of insights and expertise in growth investments. Backed by the investment of Sequoia China, Shixiang is dedicated to providing successful entrepreneurs, new economy leaders, and Sequoia China members with a long-term competitive portfolio service. ',
    'Content50DataSource.title':'We research and invest in themes and companies bringing disruptive innovation and facing the future.',
    'Content50DataSource.block1.title':'Gloabal SaaS',
    'Content50DataSource.block2.title':'Global Fintech',
    'Content50DataSource.block3.title':'Space Exploration',
    'Content50DataSource.block4.title':'Crypto',
    'Content50DataSource.block5.title':'Metaverse&Gaming',
    'Content50DataSource.block6.title':'Biotech',
    'Feature30DataSource.title':'Investment as a Service',
    'Feature30DataSource.content':'We are committed to democratizing knowledge and insights, empowering our clients to make independent investment decisions.',
    'Feature30DataSource.block1.title':'Advising',
    'Feature30DataSource.block2.title':'Alternative',
    'Feature30DataSource.block3.title':'Circulation',
    'Feature30DataSource.block4.title':'Research',
    'Feature30DataSource.block5.title':'Ecosystem',
    'Feature30DataSource.block6.title':'Evergreen',
    'Feature30DataSource.block1.content':'Advising top Founders and Leaders in the next 20 years',
    'Feature30DataSource.block2.content':'Alternative investment capabilities that empowers Founders’ asset allocation',
    'Feature30DataSource.block3.content':'Insights and assets circulation among founders in different stage',
    'Feature30DataSource.block4.content':'Deep Research in exponential growth industry and companies',
    'Feature30DataSource.block5.content':'Ecosystem of scientists and founders with knowledge sharing',
    'Feature30DataSource.block6.content':'Flexible and Evergreen investment vehicles set up for the most important companies in the next 20 years',
    'Banner10DataSource.title':'Investment Strategy',
    'Banner10DataSource.content':'We partner with leading companies  defining the future.',
    'Banner10DataSource.block1.title':'Category leader in a large, innovative and high growth market',
    'Banner10DataSource.block2.title':'Solid business model & Positive unit economics',
    'Banner10DataSource.block3.title':'Outstanding leadership team',
    'Feature00DataSource.P.title':'Strategic Capital Program',
    'Feature00DataSource.P.content':'Category leader in a large, innovative and high growth market',
    'Feature00DataSource.P.block1':'CASE FUNDS',
    'Feature00DataSource.P.block2':'FOF',
    'Feature00DataSource.P.block3':'THEMETIC FUNDS',
    'Feature00DataSource.P.block4':'Crypto',
    'Feature00DataSource.P.block5':'MANAGED ACCOUNTS',
    'Teams40DataSource.title':'Meet our team',
    'Teams40DataSource.content':'Young and Fast Growing Founding Team. Operation on complicated deal transactions and best practices',
    'Teams30DataSource.title':'Follow unicorn observer Wechat public account',
    'Teams30DataSource.content':'Shixiang research team only focuses top companies',

}
export default Index