import React from 'react';
import TweenOne from 'rc-tween-one';
import { Menu } from 'antd';
import { getChildrenToRender } from './utils';
import Pubsub from 'pubsub-js'
import { Link } from "react-router-dom";
const { Item, SubMenu } = Menu;

class Header3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: undefined,
      language: 'en_US',
      selected: ['home']
    };
  }

  componentDidMount() {
    const key = window.location.pathname.replace('/', '')
    this.setState({
      selected: [key === '' ? 'home' : key]
    })
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', this.state.language);
    }
  }


  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };

  languageClick = () => {
    const lang = this.state.language === 'en_US' ? 'zh_CN' : 'en_US'
    this.setState({
      language: lang
    });
    Pubsub.publish('language', lang)
    localStorage.setItem('language', lang);
  }

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { phoneOpen } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = navData.map((item) => {
      const { children: a, subItem, ...itemProps } = item;
      return (
        <Item key={item.name} {...itemProps} >
          {item.name !== 'careers' ?
            <Link to={item.name}>
              {a}
            </Link> : <span><a href="https://shixiangtech.jobs.feishu.cn/index" target="_blank" rel="noreferrer" >{a}</a></span>}
          
        </Item>
      );
    });
    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        {...dataSource.wrapper}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
        >
          <TweenOne
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
            {...dataSource.logo}
          >
            <img width="100%" src={dataSource.logo.children} alt="img" />
          </TweenOne>
          {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <span style={{display: this.state.phoneOpen?'none':'block'}} className="lang"  onClick={() => this.languageClick()}>
            <span style={{ color: this.state.language === 'en_US' ? '#000000' : '#808080d4' }}>English</span>/
            <span style={{ color: this.state.language === 'zh_CN' ? '#000000' : '#808080d4' }}>中文</span>
          </span>
          <TweenOne
            {...dataSource.Menu}
            animation={
              isMobile
                ? {
                  x: 0,
                  height: 0,
                  duration: 300,
                  onStart: (e) => {
                    console.log(e);
                    if (this.state.phoneOpen) {
                      e.targets.style.height = 'auto';
                    }
                  },
                  ease: 'easeInOutQuad',
                }
                : null
            }
            moment={moment}
            reverse={!!phoneOpen}
          >
            <Menu
              className='menuDefalut'
              mode={isMobile ? 'inline' : 'horizontal'}
              theme="light"
              selectedKeys={this.state.selected}
              onSelect={(e) => {
                this.setState({
                  selected: [e.key]
                })
              }}
            >
              {navChildren}
            </Menu>
          </TweenOne>
        </div>
      </TweenOne>
    );
  }
}

export default Header3;
