/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { getChildrenToRender } from './utils'
import { Parallax } from 'rc-scroll-anim'

class Banner5 extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    }
  }
  handleClick = () => {
    let anchorElement = document.getElementById('Feature7_0')
    console.log(anchorElement)
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

   getCavans =()=>  {
    var canvas = document.querySelector('#scene')
    var width = canvas.offsetWidth,
      height = canvas.offsetHeight

    // eslint-disable-next-line no-undef
    var renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true
    })
    renderer.setPixelRatio(window.devicePixelRatio > 1 ? 2 : 1)
    renderer.setSize(width, height)
    // renderer.setClearColor(0xffffff00);
    // eslint-disable-next-line no-undef
    var scene = new THREE.Scene()

    var camera = new THREE.PerspectiveCamera(50, width / height, 0.1, 2000)
    camera.position.set(20, 0, 80)

    var loader = new THREE.TextureLoader()
    loader.crossOrigin = 'Anonymous'
    var dotTexture = loader.load(
      'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/dotTexture.png'
    )

    var radius = 50
    var sphereGeom = new THREE.IcosahedronGeometry(radius, 5)
    var dotsGeom = new THREE.Geometry()
    var bufferDotsGeom = new THREE.BufferGeometry()
    var positions = new Float32Array(sphereGeom.vertices.length * 3)
    for (var i = 0; i < sphereGeom.vertices.length; i++) {
      var vector = sphereGeom.vertices[i]
      animateDot(i, vector)
      dotsGeom.vertices.push(vector)
      vector.toArray(positions, i * 3)
    }

    function animateDot(index, vector) {
      TweenMax.to(vector, 4, {
        x: 0,
        z: 0,
        ease: Back.easeOut,
        delay: Math.abs(vector.y / radius) * 2,
        repeat: -1,
        yoyo: true,
        yoyoEase: Back.easeOut,
        onUpdate: function () {
          updateDot(index, vector)
        }
      })
    }
    function updateDot(index, vector) {
      positions[index * 3] = vector.x
      positions[index * 3 + 2] = vector.z
    }

    var attributePositions = new THREE.BufferAttribute(positions, 3)
    bufferDotsGeom.addAttribute('position', attributePositions)
    var shaderMaterial = new THREE.ShaderMaterial({
      uniforms: {
        texture: {
          value: dotTexture
        }
      },
      vertexShader: document.getElementById('wrapVertexShader').textContent,
      fragmentShader: document.getElementById('wrapFragmentShader').textContent,
      transparent: true
    })
    var dots = new THREE.Points(bufferDotsGeom, shaderMaterial)
    scene.add(dots)

    function render(a) {
      dots.geometry.verticesNeedUpdate = true
      dots.geometry.attributes.position.needsUpdate = true
      renderer.render(scene, camera)
    }

    function onResize() {
      canvas.style.width = ''
      canvas.style.height = ''
      width = canvas.offsetWidth 
      height = canvas.offsetHeight 
      camera.aspect = width / height
      camera.updateProjectionMatrix()
      renderer.setSize(width, height)
    }

    var mouse = new THREE.Vector2(0.8, 0.5)
    function onMouseMove(e) {
      mouse.x = e.clientX / window.innerWidth - 0.5
      mouse.y = e.clientY / window.innerHeight - 0.5
      TweenMax.to(dots.rotation, 4, {
        x: mouse.y * Math.PI * 0.5,
        z: mouse.x * Math.PI * 0.2,
        ease: Power1.easeOut
      })
    }

    TweenMax.ticker.addEventListener('tick', render)
    window.addEventListener('mousemove', onMouseMove)
    var resizeTm
    window.addEventListener('resize', function () {
      resizeTm = clearTimeout(resizeTm)
      resizeTm = setTimeout(onResize, 200)
      
    })
    this.setState({show: true})
  }

  componentDidMount() {
    if(!this.props.isMobile){
      this.getCavans()
    }
  }

  render() {
    const { ...tagProps } = this.props
    const { dataSource } = tagProps
    delete tagProps.dataSource
    const stl = {
      width: '100%',
      height: tagProps.isMobile?'241px':'100vh',
    }
    // delete tagProps.isMobile
   
    const animType = {
      queue: 'bottom',
      one: {
        y: '+=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad'
      }
    }
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <canvas
          class="scene scene--full"
          id="scene"
          width={stl.width}
          height={stl.height}
          style={{ width: stl.width, height: stl.height, position: 'absolute',opacity: this.state.show?1:0}}
        ></canvas>

        <div {...dataSource.page}>
          <QueueAnim
            key="text"
            type={animType.queue}
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            {...dataSource.childWrapper}
            componentProps={{
              md: dataSource.childWrapper.md,
              xs: dataSource.childWrapper.xs
            }}
          >
            {dataSource.childWrapper.children.map(getChildrenToRender)}
          </QueueAnim>
          <TweenOne animation={animType.one} key="title" {...dataSource.image}>
            <img src={dataSource.image.children} width="100%" alt="img" />
          </TweenOne>
        </div>

        <div className="banner5-icon-bg">
          {/* <TweenOne
       
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner5-icon"
          key="icon"
        >
          <img
          onClick={() => {
            this.handleClick();
          }}
          style={{zIndex:99}}
          
          src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/arrow.svg"></img>
        </TweenOne> */}
        </div>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000
          }}
          className="banner5-icon"
          key="icon"
        >
          <img
            onClick={() => {
              this.handleClick()
            }}
            style={{ zIndex: 99 }}
            src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/arrow.svg"
          ></img>
        </TweenOne>
        <svg
          className="banner5-bgimg"
          viewBox="0 0 1440 871"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="l1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M855.268 668.353C855.268 669.635 854.276 670.665 853.041 670.665C851.807 670.665 850.814 669.635 850.814 668.353C850.814 667.071 851.807 666.041 853.041 666.041C854.276 666.041 855.268 667.094 855.268 668.353Z"
            fill="#6AC2FF"
          />
          <path
           className="l2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M862.28 251.797C862.28 253.079 861.288 254.11 860.053 254.11C858.818 254.11 857.826 253.079 857.826 251.797C857.826 250.516 858.818 249.485 860.053 249.485C861.288 249.485 862.28 250.516 862.28 251.797Z"
            fill="#6AC2FF"
          />
          <path
            className="l3"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1182.91 540.932C1182.91 542.214 1181.92 543.244 1180.69 543.244C1179.45 543.244 1178.46 542.214 1178.46 540.932C1178.46 539.65 1179.45 538.62 1180.69 538.62C1181.92 538.62 1182.91 539.65 1182.91 540.932Z"
            fill="#6AC2FF"
          />
          <path
           className="l4"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M601.952 714.254C601.952 715.536 600.96 716.566 599.725 716.566C598.49 716.566 597.498 715.536 597.498 714.254C597.498 712.972 598.49 711.941 599.725 711.941C600.96 711.964 601.952 712.995 601.952 714.254Z"
            fill="#6AC2FF"
          />
          <path
            className="l5"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1432.53 682.386C1432.53 684.882 1430.59 686.919 1428.16 686.919C1425.76 686.919 1423.79 684.882 1423.79 682.386C1423.79 679.891 1425.76 677.854 1428.16 677.854C1430.59 677.854 1432.53 679.891 1432.53 682.386Z"
            fill="#B688FF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1242.6 110.8C1242.6 112.082 1241.61 113.113 1240.37 113.113C1239.14 113.113 1238.14 112.082 1238.14 110.8C1238.14 109.518 1239.14 108.488 1240.37 108.488C1241.61 108.488 1242.6 109.518 1242.6 110.8Z"
            fill="#6AC2FF"
          />
          <path
            className="l1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M299.62 23.9684C299.62 25.2504 298.628 26.2806 297.393 26.2806C296.158 26.2806 295.166 25.2504 295.166 23.9684C295.166 22.6864 296.158 21.6562 297.393 21.6562C298.628 21.6562 299.62 22.6864 299.62 23.9684Z"
            fill="#6AC2FF"
          />
          <path
            fill-rule="evenodd"
            className="l2"
            clip-rule="evenodd"
            d="M745.731 65.4499C745.731 66.7319 744.739 67.762 743.504 67.762C742.269 67.762 741.277 66.7319 741.277 65.4499C741.277 64.1679 742.269 63.1377 743.504 63.1377C744.739 63.1606 745.731 64.1908 745.731 65.4499Z"
            fill="#6AC2FF"
          />
          <path
            className="l3"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M579.329 2.31217C579.329 3.59416 578.337 4.62433 577.102 4.62433C575.867 4.62433 574.875 3.59416 574.875 2.31217C574.875 1.03017 575.867 0 577.102 0C578.337 0 579.329 1.03017 579.329 2.31217Z"
            fill="#6AC2FF"
          />
          <path
            fill-rule="evenodd"
            className="l4"
            clip-rule="evenodd"
            d="M318.692 57.8268C318.692 59.1088 317.7 60.139 316.465 60.139C315.23 60.139 314.238 59.1088 314.238 57.8268C314.238 56.5448 315.23 55.5146 316.465 55.5146C317.7 55.5146 318.692 56.5448 318.692 57.8268Z"
            fill="#6AC2FF"
          />
          <path
            d="M1337.56 31.3861C1338.79 31.3861 1339.79 30.3509 1339.79 29.0739C1339.79 27.7969 1338.79 26.7617 1337.56 26.7617C1336.33 26.7617 1335.33 27.7969 1335.33 29.0739C1335.33 30.3509 1336.33 31.3861 1337.56 31.3861Z"
            fill="#6AC2FF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1056.71 248.707C1056.71 249.989 1055.71 251.019 1054.48 251.019C1053.24 251.019 1052.25 249.989 1052.25 248.707C1052.25 247.425 1053.24 246.395 1054.48 246.395C1055.71 246.395 1056.71 247.425 1056.71 248.707Z"
            fill="#6AC2FF"
          />
          <path
            className="l1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M837.917 5.05924C837.917 6.34123 836.925 7.3714 835.69 7.3714C834.455 7.3714 833.463 6.34123 833.463 5.05924C833.463 3.77724 834.455 2.74707 835.69 2.74707C836.925 2.74707 837.917 3.77724 837.917 5.05924Z"
            fill="#6AC2FF"
          />
          <path
            fill-rule="evenodd"
            className="l2"
            clip-rule="evenodd"
            d="M291.507 599.034C291.507 600.316 290.514 601.346 289.28 601.346C288.045 601.346 287.053 600.316 287.053 599.034C287.053 597.752 288.045 596.722 289.28 596.722C290.514 596.722 291.507 597.752 291.507 599.034Z"
            fill="#6AC2FF"
          />
          <path
            className="l3"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M198.945 743.557C198.945 747.059 196.211 749.921 192.815 749.921C189.442 749.921 186.686 747.082 186.686 743.557C186.686 740.054 189.42 737.192 192.815 737.192C196.211 737.192 198.945 740.031 198.945 743.557Z"
            fill="#B688FF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M741.741 718.168C741.741 719.45 740.749 720.48 739.514 720.48C738.279 720.48 737.287 719.45 737.287 718.168C737.287 716.886 738.279 715.855 739.514 715.855C740.749 715.878 741.741 716.908 741.741 718.168Z"
            fill="#6AC2FF"
            className="l4"
          />
          <path
            className="l5"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M265.157 180.738C265.157 182.02 264.165 183.05 262.93 183.05C261.695 183.05 260.703 182.02 260.703 180.738C260.703 179.456 261.695 178.426 262.93 178.426C264.165 178.449 265.157 179.479 265.157 180.738Z"
            fill="#6AC2FF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.43179 30.8366C4.43179 32.1186 3.4396 33.1487 2.20487 33.1487C0.970142 33.1487 0 32.1186 0 30.8366C0 29.5546 0.992191 28.5244 2.22692 28.5244C3.46164 28.5244 4.43179 29.5775 4.43179 30.8366Z"
            fill="#6AC2FF"
          />
          <path
            className="l1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1277.63 868.688C1277.63 869.97 1276.64 871 1275.41 871C1274.17 871 1273.18 869.97 1273.18 868.688C1273.18 867.406 1274.17 866.376 1275.41 866.376C1276.64 866.376 1277.63 867.429 1277.63 868.688Z"
            fill="#6AC2FF"
          />
          <path
            className="l2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M890.194 810.357C890.194 811.639 889.202 812.669 887.967 812.669C886.732 812.669 885.74 811.639 885.74 810.357C885.74 809.075 886.732 808.045 887.967 808.045C889.202 808.068 890.194 809.098 890.194 810.357Z"
            fill="#6AC2FF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M94.655 852.159C94.655 853.441 93.6628 854.471 92.4281 854.471C91.1934 854.471 90.2012 853.441 90.2012 852.159C90.2012 850.877 91.1934 849.847 92.4281 849.847C93.6628 849.847 94.655 850.9 94.655 852.159Z"
            className="l3"
            fill="#6AC2FF"
          />
          <path
            className="l4"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M233.518 798.911C233.518 800.193 232.526 801.223 231.291 801.223C230.057 801.223 229.064 800.193 229.064 798.911C229.064 797.629 230.057 796.599 231.291 796.599C232.526 796.599 233.518 797.629 233.518 798.911Z"
            fill="#6AC2FF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1053.75 713.956C1053.75 715.238 1052.76 716.268 1051.52 716.268C1050.29 716.268 1049.3 715.238 1049.3 713.956C1049.3 712.674 1050.29 711.644 1051.52 711.644C1052.76 711.666 1053.75 712.697 1053.75 713.956Z"
            className="l5"
            fill="#6AC2FF"
          />
          <path
            className="l1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1066.67 100.728C1066.67 103.521 1064.49 105.81 1061.78 105.81C1059.09 105.81 1056.88 103.544 1056.88 100.728C1056.88 97.9348 1059.07 95.6455 1061.78 95.6455C1064.49 95.6455 1066.67 97.9348 1066.67 100.728Z"
            fill="#B688FF"
          />
          <path
            className="l1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1440 233.369C1440 234.651 1439.01 235.681 1437.77 235.681C1436.54 235.681 1435.55 234.651 1435.55 233.369C1435.55 232.087 1436.54 231.057 1437.77 231.057C1439.01 231.057 1440 232.087 1440 233.369Z"
            fill="#6AC2FF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M704.454 614.312C704.454 615.594 703.462 616.624 702.227 616.624C700.992 616.624 700 615.594 700 614.312C700 613.03 700.992 612 702.227 612C703.462 612.023 704.454 613.053 704.454 614.312Z"
            className="l2"
            fill="#6AC2FF"
          />
          <path
            className="l3"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1023.48 533.035C1023.48 534.317 1022.49 535.347 1021.25 535.347C1020.02 535.347 1019.02 534.317 1019.02 533.035C1019.02 531.753 1020.02 530.723 1021.25 530.723C1022.49 530.746 1023.48 531.776 1023.48 533.035Z"
            fill="#6AC2FF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1148.82 568.336C1148.82 569.618 1147.83 570.648 1146.6 570.648C1145.36 570.648 1144.37 569.618 1144.37 568.336C1144.37 567.054 1145.36 566.023 1146.6 566.023C1147.83 566.046 1148.82 567.076 1148.82 568.336Z"
            className="l4"
            fill="#6AC2FF"
          />
          <path
            d="M106.032 351.152C107.261 351.152 108.259 350.117 108.259 348.84C108.259 347.563 107.261 346.527 106.032 346.527C104.802 346.527 103.805 347.563 103.805 348.84C103.805 350.117 104.802 351.152 106.032 351.152Z"
            className="l5"
            fill="#6AC2FF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.0202 661.623C20.0202 662.905 19.0281 663.935 17.7933 663.935C16.5586 663.935 15.5664 662.905 15.5664 661.623C15.5664 660.341 16.5586 659.311 17.7933 659.311C19.0281 659.311 20.0202 660.341 20.0202 661.623Z"
            fill="#6AC2FF"
          />
        </svg>

        {/* <img className="banner5-bgimg" src={'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg21.png'} /> */}
      </div>
    )
  }
}
export default Banner5
