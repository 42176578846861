import React from 'react'
import { FormattedMessage } from 'react-intl'

export const Feature30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3 philosophy' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p className="content3-text">
              <span>
                <FormattedMessage id="Feature30DataSource.title" />
              </span>
              <img
                alt="Investment as a Service"
                className="content3-text-icon"
                src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/textbg3.png"
              />
            </p>
          </span>
        ),
        className: 'title-h1 l3jvhfbfzzd-editor_css'
      },
      {
        name: 'content',
        className: 'title-content l3jvi2ssiz-editor_css',
        children: (
          <span>
            <p>
              <FormattedMessage id="Feature30DataSource.content" />
              &nbsp;
            </p>
            <img
              src={
                'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/line1.svg'
              }
              alt=""
              className="line"
            />
          </span>
        )
      }
    ]
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block1',
        className: 'content3-block block1-bg',
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/service1.svg'
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title l3jvj217fbt-editor_css',
            children: <FormattedMessage id="Feature30DataSource.block1.title" />
          },
          content: {
            className: 'content3-content l3jvji77ws-editor_css',
            children: (
              <FormattedMessage id="Feature30DataSource.block1.content" />
            )
          }
        }
      },
      {
        name: 'block~l3jvk2f63lf',
        className: 'content3-block block2-bg',
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/service2.svg'
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title l3jvj217fbt-editor_css',
            children: <FormattedMessage id="Feature30DataSource.block2.title" />
          },
          content: {
            className: 'content3-content l3jvji77ws-editor_css',
            children: (
              <FormattedMessage id="Feature30DataSource.block2.content" />
            )
          }
        }
      },
      {
        name: 'block~l3jvk2tmzq7',
        className: 'content3-block block3-bg',
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/service3.svg'
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title l3jvj217fbt-editor_css',
            children: <FormattedMessage id="Feature30DataSource.block3.title" />
          },
          content: {
            className: 'content3-content l3jvji77ws-editor_css',
            children: (
              <FormattedMessage id="Feature30DataSource.block3.content" />
            )
          }
        }
      },
      {
        name: 'block~l3jvk3cqtgl',
        className: 'content3-block block4-bg',
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/service4.svg'
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title l3jvj217fbt-editor_css',
            children: <FormattedMessage id="Feature30DataSource.block4.title" />
          },
          content: {
            className: 'content3-content l3jvji77ws-editor_css',
            children: (
              <FormattedMessage id="Feature30DataSource.block4.content" />
            )
          }
        }
      },
      {
        name: 'block~l3jvk40yhw',
        className: 'content3-block  block5-bg',
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/service5.svg'
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title l3jvj217fbt-editor_css',
            children: <FormattedMessage id="Feature30DataSource.block5.title" />
          },
          content: {
            className: 'content3-content l3jvji77ws-editor_css',
            children: (
              <FormattedMessage id="Feature30DataSource.block5.content" />
            )
          }
        }
      },
      {
        name: 'block~l3jvk4iynbr',
        className: 'content3-block block6-bg',
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/service6.svg'
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title l3jvj217fbt-editor_css',
            children: <FormattedMessage id="Feature30DataSource.block6.title" />
          },
          content: {
            className: 'content3-content l3jvji77ws-editor_css',
            children: (
              <FormattedMessage id="Feature30DataSource.block6.content" />
            )
          }
        }
      }
    ]
  }
}
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner1-title',
          children: (
            <span>
              <p className="banner1-title-text">
                <span>
                  <FormattedMessage id="Banner10DataSource.title" />
                </span>
                <img
                  alt="Investment Strategy"
                  className="banner1-text-icon"
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/textbg5.png"
                />
              </p>
              <p className="banner1-content">
                <FormattedMessage id="Banner10DataSource.content" />
              </p>
            </span>
          )
        },
        content: {
          className: 'banner1-block',
          children: (
            <div className="banner1-block-div div1">
              <div className="banner1-block-title">
              <FormattedMessage id="Banner10DataSource.block1.title" />
              </div>
              <div className="banner1-block-img">
                <img
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/datap2.png"
                  alt=""
                />
              </div>
            </div>
          )
        }
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
        title: {
          className: 'banner1-title',
          children: (
            <span>
              <p className="banner1-title-text">
                <span>
                  <FormattedMessage id="Banner10DataSource.title" />
                </span>
                <img
                  alt="Investment Strategy"
                  className="banner1-text-icon"
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/textbg5.png"
                />
              </p>
              <p className="banner1-content">
                <FormattedMessage id="Banner10DataSource.content" />
              </p>
            </span>
          )
        },
        content: {
          className: 'banner1-block',
          children: (
            <div className="banner1-block-div div2">
              <div className="banner1-block-title">
              <FormattedMessage id="Banner10DataSource.block2.title" />
              </div>
              <div className="banner1-block-img">
                <img
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/datap2.png"
                  alt=""
                />
              </div>
            </div>
          )
        }
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg2' },
        title: {
          className: 'banner1-title',
          children: (
            <span>
            <p className="banner1-title-text">
              <span>
                <FormattedMessage id="Banner10DataSource.title" />
              </span>
              <img
                alt="Investment Strategy"
                className="banner1-text-icon"
                src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/textbg5.png"
              />
            </p>
            <p className="banner1-content">
              <FormattedMessage id="Banner10DataSource.content" />
            </p>
          </span>
          )
        },
        content: {
          className: 'banner1-content',
          children: (
            <div className="banner1-block-div div3">
              <div className="banner1-block-title">
              <FormattedMessage id="Banner10DataSource.block3.title" />
              </div>
              <div className="banner1-block-img">
                <img
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/datap3.png"
                  alt=""
                />
              </div>
            </div>
          )
        }
      }
    ]
  }
}
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p className="content3-text">
              <span> <FormattedMessage id="Feature00DataSource.P.title" /></span>
              <img
                alt="Investment as a Service"
                className="content0-text-icon2"
                src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/textbg6.png"
              />
            </p>
          </span>
          
        )
      },
      {
        name: 'content',
        className: 'title-content l3jvi2ssiz-editor_css',
        children: (
          <span>
            <p className="l3jvi2ssiz">
              <FormattedMessage id="Feature00DataSource.P.content" />
              &nbsp;
            </p>
          </span>
        )
      }
    ]
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 5,
        xs: 8,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (
                <img
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/category1.svg"
                  alt=""
                />
              )
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <FormattedMessage id="Feature00DataSource.P.block1" />
            }
          ]
        }
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 5,
        xs: 8,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (
                <img
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/category2.svg"
                  alt=""
                />
              )
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <FormattedMessage id="Feature00DataSource.P.block2" />
            }
          ]
        }
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 4,
        xs: 8,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (
                <img
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/category3.svg"
                  alt=""
                />
              )
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <FormattedMessage id="Feature00DataSource.P.block3" />
            }
          ]
        }
      },
      {
        name: 'block3',
        className: 'content0-block',
        md: 5,
        xs: 8,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (
                <img
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/category4.svg"
                  alt=""
                />
              )
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <FormattedMessage id="Feature00DataSource.P.block4" />
            }
          ]
        }
      },
      {
        name: 'block4',
        className: 'content0-block',
        md: 5,
        xs: 12,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (
                <img
                  src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/category5.svg"
                  alt=""
                />
              )
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <FormattedMessage id="Feature00DataSource.P.block5" />
            }
          ]
        }
      }
    ]
  }
}
