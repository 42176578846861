import React from 'react';
import { Card,Avatar } from 'antd';
import { FormattedMessage } from "react-intl";

const { Meta } = Card;
export const Teams40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content8-wrapper l3ltk50g5si-editor_css',
  },
  page: { className: 'home-page content8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: <span>
        <p className="content8-text">
          <span> <FormattedMessage id="Teams40DataSource.title" /></span>
          <img alt="Investment as a Service" className="content8-text-icon" src="https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/textbg7.png" />
        </p>
    
      </span>,
        className: 'title-h1 l3ltimgvn89-editor_css',
      },
      {
        name: 'content~l3ltfebtqim',
        className: 'l3ltisirm0m-editor_css',
        children: <FormattedMessage id="Teams40DataSource.content" />,
      },
    ],
  },
  block: {
    className: 'content-wrapper',
    gutter: 37,
    children: [
      {
        name: 'block0',
        lg: 6,
        md: 8,
        sm: 8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:"https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/guangmi.png"
              ,
          },
          title: { className: 'content8-title', children: 'Guangmi LI' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block1',
        sm:8,
        lg: 6,
        md: 8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/binlu.jpg',
          },
          title: { className: 'content8-title', children: 'Binlu MA' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block2',
        sm:8,
        lg: 6,
        md: 8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/hangbin.jpg',
          },
          title: { className: 'content8-title', children: 'Hangbin LI' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block3',
        sm:8,
        lg: 6,
        md: 8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/rita.jpg',
          },
          title: { className: 'content8-title', children: 'Rita TIAN' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block3',
        sm:8,
        lg: 6,
        md: 8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/haoyu.jpg',
          },
          title: { className: 'content8-title', children: 'Haoyu CHEN' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
     
      {
        name: 'block4',
        lg: 6,
        md: 8,
        sm:8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/zhangxi.jpg',
          },
          title: { className: 'content8-title', children: 'Jessie ZHANG' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block5',
        sm:8,
        lg: 6,
        md: 8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/jiahao.jpg',
          },
          title: { className: 'content8-title', children: 'Jiahao QIN' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block6',
        lg: 6,
        md: 8,
        sm:8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/liaokun.jpg',
          },
          title: { className: 'content8-title', children: 'Kelvin LIAO' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block7',
        sm:8,
        lg: 6,
        md: 8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img imgcover',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/max.png',
          },
          title: { className: 'content8-title', children: 'Max CHEN' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block~l3ltrdl47fd',
        lg: 6,
        md: 8,
        sm:8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/penny.jpg',
          },
          title: { className: 'content8-title', children: 'Penny DENG' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block~l3ltre2fl1h',
        lg: 6,
        md: 8,
        sm:8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/siqi.jpg',
          },
          title: { className: 'content8-title', children: 'Siqi HU' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block~l3ltrfvj9ms',
        lg: 6,
        md: 8,
        sm:8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img ',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/yilong.jpg',
          },
          title: { className: 'content8-title', children: 'Yilong HE' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block~l3ltrgnzcsp',
        sm:8,
        lg: 6,
        md: 8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img imgcover',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/yuanhong.jpg',
          },
          title: { className: 'content8-title', children: 'Yuanhong LIU' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block~l3ltri1jfa',
        lg: 6,
        md: 8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img imgcover ',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/tianyi.jpg',
          },
          title: { className: 'content8-title', children: 'Patrick CHENG' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block~l3ltri1jfa1',
        lg: 6,
        md: 8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/chengjia.jpg',
          },
          title: { className: 'content8-title', children: 'Chengjia LI' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block~l3ltri1jfa',
        lg: 6,
        md: 8,
        xs: 12,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/team/shitong.jpg',
          },
          title: { className: 'content8-title', children: 'Shitong GUO' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
    ],
  },
};
